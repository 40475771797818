import React from 'react';
import moment from 'moment';
import { FaApple, FaGooglePlay, FaFacebookF, FaInstagram, FaLinkedinIn, FaYoutube, FaTwitter } from 'react-icons/fa';
import { Link } from 'react-router-dom';

export default function Footer() {
    const currentYear = moment().format('YYYY');

    return (
        <footer className="footer brand-1">
            <div className="container">
                <div className="footer__wrapper">
                    <div className="footer__top footer__top--style1">
                        <div className="row gy-5 gx-4">
                            <div className="col-12 col-md-4">
                                <div className="footer__about">
                                    <Link to="/" className="footer__about-logo">
                                        <img src="/assets/images/logo.png" className="logo" alt="Logo" />
                                    </Link>
                                    <p className="footer__about-moto">
                                        Welcome to our trading site! We offer the best, most affordable products and services around. Shop now and start finding great deals!
                                    </p>
                                    <div className="footer__app">
                                        <div className="footer__app-item footer__app-item--apple">
                                            <div className="footer__app-inner">
                                                <div className="footer__app-thumb">
                                                    <a href="https://www.apple.com/app-store/" target="_blank" rel="noreferrer" className="stretched-link">
                                                        <FaApple size={32} />
                                                    </a>
                                                </div>
                                                <div className="footer__app-content">
                                                    <span>Download on the</span>
                                                    <p className="mb-0">App Store</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="footer__app-item footer__app-item--playstore">
                                            <div className="footer__app-inner">
                                                <div className="footer__app-thumb">
                                                    <a href="https://play.google.com/store" target="_blank" rel="noreferrer" className="stretched-link">
                                                        <FaGooglePlay size={32} />
                                                    </a>
                                                </div>
                                                <div className="footer__app-content">
                                                    <span>GET IT ON</span>
                                                    <p className="mb-0">Google Play</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-8">
                                <div className="row">
                                    <div className="col-6 col-md-3">
                                        <div className="footer__links">
                                            <div className="footer__links-tittle">
                                                <h6>Company</h6>
                                            </div>
                                            <div className="footer__links-content">
                                                <ul className="footer__linklist">
                                                    <li className="footer__linklist-item"><Link>Blog</Link></li>
                                                    <li className="footer__linklist-item"><a href="mailto:Support@xenely.com" target='_blank' rel='noreferrer'>Support</a></li>
                                                    <li className="footer__linklist-item"><a href="https://t.me/5228013193" target="_blank" rel="noopener noreferrer">Telegram</a></li>
                                                    <li className="footer__linklist-item"><Link to="/careers">Careers</Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-3">
                                        <div className="footer__links">
                                            <div className="footer__links-tittle">
                                                <h6>Services</h6>
                                            </div>
                                            <div className="footer__links-content">
                                                <ul className="footer__linklist">
                                                    <li className="footer__linklist-item"><Link to="/otc">OTC Desk</Link></li>
                                                    <li className="footer__linklist-item"><Link>International Expansion</Link></li>
                                                    <li className="footer__linklist-item"><Link to="/institutions">Institutions</Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-3">
                                        <div className="footer__links">
                                            <div className="footer__links-tittle">
                                                <h6>International Expansion</h6>
                                            </div>
                                            <div className="footer__links-content">
                                                <ul className="footer__linklist">
                                                    <li className="footer__linklist-item"><Link>United Arab Emirates</Link></li>
                                                    <li className="footer__linklist-item"><Link>Europe</Link></li>
                                                    <li className="footer__linklist-item"><Link>West Africa</Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-3">
                                        <div className="footer__links">
                                            <div className="footer__links-tittle">
                                                <h6>Legal</h6>
                                            </div>
                                            <div className="footer__links-content">
                                                <ul className="footer__linklist">
                                                    <li className="footer__linklist-item"><Link to="/terms-of-service">Terms and Conditions</Link></li>
                                                    <li className="footer__linklist-item"><Link to="/disclosure-statement">Disclosure Statement</Link></li>
                                                    <li className="footer__linklist-item"><Link>PAIA Manual</Link></li>
                                                    <li className="footer__linklist-item"><Link>Privacy Policy</Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer__bottom">
                        <div className="footer__end">
                            <div className="footer__end-copyright">
                                <p className="mb-0">Copyright © {currentYear} Xeno Labs - All Rights Reserved.</p>
                            </div>
                            <div>
                                <ul className="social">
                                    <li className="social__item">
                                        <a href="/" className="social__link social__link--style22"> <FaFacebookF /></a>
                                    </li>
                                    <li className="social__item">
                                        <a href="/" className="social__link social__link--style22"> <FaInstagram /></a>
                                    </li>
                                    <li className="social__item">
                                        <a href="/" className="social__link social__link--style22"> <FaLinkedinIn /></a>
                                    </li>
                                    <li className="social__item">
                                        <a href="/" className="social__link social__link--style22"> <FaYoutube /></a>
                                    </li>
                                    <li className="social__item">
                                        <a href="/" className="social__link social__link--style22"> <FaTwitter /></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer__shape">
                <span className="footer__shape-item footer__shape-item--1"><img src="/assets/images/footer/1.png" alt="shape icon" /></span>
                <span className="footer__shape-item footer__shape-item--2"><span></span></span>
            </div>
        </footer>
    );
}
