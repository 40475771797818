import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import LogTable from '../../components/Tables/LogTable';

export default function Dashboard() {
    const bgImage = '/assets/images/welcome_banner.webp';
    const userDetails = useSelector((state) => state.user.userDetails);

    const renderVerificationMessage = () => {
        if (userDetails?.isKyc) {
            return (
                <p>
                    <strong>
                        Your account is verified and unrestricted. Your withdrawal limit is Unlimited.
                    </strong>
                </p>
            );
        } else {
            return (
                <>
                    <p>
                        <strong>
                            Enjoy unrestricted access to the world of cryptocurrency. But first, you need to verify your account. Let's get you set up and ready to trade:
                        </strong>
                    </p>
                    <Link to="/client/kyc" className='btn btn-success px-4'>Next</Link>
                </>
            );
        }
    };

    return (
        <div className="page">
            <div className="container">
                <div className="row mt-4">
                    <div className="col-12">
                        <div className="bg_welcome" style={{ backgroundImage: `url(${bgImage})` }}>
                            <div className="welcome_content">
                                <h1 className='text-capitalize'>Welcome to XENLY</h1>
                                {renderVerificationMessage()}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row p-5">
                    <div className="col-6">
                        <div className="card">
                            <div className="card-header">
                                <strong>Verification Level</strong>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-2">
                                        {userDetails?.avatar && <img src={userDetails?.avatar} alt={userDetails?.name} className='rounded-circle shadow-4-strong' />}
                                    </div>
                                    <div className="col-10">
                                        <h5><strong>{userDetails?.name}</strong></h5>
                                        <h5><strong>{userDetails?.email}</strong></h5>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <strong>You are currently at Level {userDetails?.KYCProgress}</strong>
                                    </div>
                                </div>
                                <div className="row border-rounded p-4 my-3" style={{ backgroundColor: '#f8f8f9' }}>
                                    <b className='mb-0'>Withdrawal Limits:</b>
                                    <p className='mb-0'>Level 1: R0</p>
                                    <p className='mb-0'>Level 2: R5000</p>
                                    <p className='mb-0'>Level 3: R50 million</p>
                                    <p className='mb-0'>Level 4: Unlimited</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="card">
                            <div className="card-header">
                                <strong>Announcements</strong>
                            </div>
                            <div className="card-body">
                                {/* Announcements can be added here */}
                            </div>
                        </div>
                        <div className="card mt-2">
                            <div className="card-body">
                                <LogTable />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
